import '@assets/scss/pages/legal.scss';

import React from 'react';
import Helmet from 'react-helmet';
import useHover from '@assets/scripts/hooks/useHover';

// components
import Layout from '@src/layouts';
import HeroArticle from '@components/molecules/heros/HeroArticle';
import Link from '@components/atoms/LinkPage';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-legal';

const footerRelatedLinks = [
	{ title: 'Privacy Policy', url: '/en/privacy-policy/' },
];

// --------------------------------
// #endregion
// --------------------------------

const TermsOfUsePage = (props) => {
	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/conditions/', lang: 'fr' }]}
			title="Terms of Use - Superhuit"
		>
			<Helmet
				bodyAttributes={{
					class: `page ${pageClass}`,
				}}
			/>
			<HeroArticle title={'Terms of Use'} />
			<div className="wrapper">
				<section
					className="grid-content grid text-content"
					data-animation-page
				>
					<ol type="number">
						<li>
							<h2>Preamble</h2>
							<p>
								The present Website Terms of use (“
								<b>Terms of use</b>”) governs your relationship
								with the superhuit.ch website (the “
								<b>Website</b>”) and our dedicated pages on the
								following social media and platforms: Facebook,
								Twitter, Instagram, YouTube, Behance, Vimeo and
								LinkedIn, all operated by SUPERHUIT and its
								affiliate(s) (“
								<b>SUPERHUIT</b>”, “<b>us</b>”, “<b>we</b>”, “
								<b>our</b>”), which main office is located in
								Rue des Terreaux 7, 1003 Lausanne.
							</p>

							<p>
								Please read these Terms of use carefully before
								using the Website. Your access and use of the
								Website are conditional upon your acceptance of,
								and compliance with these Terms of use.
							</p>

							<p>
								<b>
									By browsing our Website, you agree to be
									bound by these Terms of use and you agree.
									If you do not consent to these Terms of use,
									we kindly ask you not to use our Website or
									dedicated pages on social media.
								</b>
							</p>
						</li>
						<li>
							<h2>Restrictions on Use</h2>
							<ol type="number-item">
								<li>
									The Website shall be used in good faith by
									you. As such, the use of the Website is in
									particular restricted as follows:
									<ol type="lower-alpha">
										<li>
											You shall not reproduce, modify,
											prepare derivative works based upon,
											sell, resell, license, sublicense,
											distribute, make available, rent or
											lease the Website or any of its
											components, feature, function or
											interface, or include the Website in
											an offering.
										</li>
										<li>
											You shall not (i) permit direct or
											indirect access to or use of the
											Website in a way that circumvents a
											contractual usage limit, (ii)
											attempt to gain unauthorized access
											to the Website or its related
											systems or networks, (iii) access
											the Website in order to build a
											competitive product or Website, (iv)
											cause or launch any programs or
											scripts for the purpose of scraping,
											indexing, surveying, or otherwise
											data mining any portion of the
											Website, or (vi) decompile, reverse
											engineer or disassemble the Website
											or portion thereof.
										</li>
										<li>
											You shall not use the Website in a
											way which threatens the security,
											integrity or availability of the
											Website; you shall in particular not
											use the Website to (i) store or
											transmit infringing, libellous, or
											otherwise unlawful or tortious
											material, (ii) store or transmit
											material in violation of third-party
											privacy rights, (iii) store or
											transmit malicious code, (iv)
											interfere with or disrupt the
											integrity or performance of the
											Website or third-party data
											contained therein.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							<h2>Intellectual Property</h2>
							<ol type="number-item">
								<li>
									The Website and its original content,
									features and functionality are and will
									remain the exclusive property of SUPERHUIT
									and its licensors. In particular, the
									corporate names, logos, photos, products,
									brands, texts and domain names mentioned on
									the Website are the sole property of
									SUPERHUIT and may not be used without the
									prior and written authorization of
									SUPERHUIT.
								</li>
								<li>
									The Website is protected by copyright,
									trademark and unfair competition laws, under
									governance of Swiss law. Our trademarks and
									trade dress may not be used in connection
									with any product or Website without the
									prior written consent of SUPERHUIT.
								</li>
								<li>
									SUPERHUIT reserves all of its rights, titles
									and interests in and to the Website,
									including all of its related intellectual
									property rights. No rights are granted to
									you under these Terms of use.
								</li>
							</ol>
						</li>
						<li>
							<h2>Disclaimer</h2>
							<ol type="number-item">
								<li>
									Your use of the Website is at your sole
									risk. The Website is provided on an “AS IS”
									and “AS AVAILABLE” basis.
								</li>
								<li>
									The information contained on the Website is
									provided without warranties of any kind,
									whether express or implied, including, but
									not limited to, implied warranties of
									merchantability, fitness for a particular
									purpose, non-infringement or course of
									performance.
								</li>
								<li>
									SUPERHUIT and SUPERHUIT’s employees do not
									assume any liability related to the use of
									the information contained on the Website,
									and SUPERHUIT or SUPERHUIT’s employees may
									not be held liable for the inexactness, nor
									errors, omissions or modification of content
									of the information available on the Website.
								</li>
								<li>
									SUPERHUIT, SUPERHUIT’s employees and
									SUPERHUIT’s licensors do NOT warrant that:
									<ol type="lower-roman">
										<li>
											the Website will function
											uninterrupted, secure or available
											at any particular time or location,
										</li>
										<li>
											any errors or defects will be
											corrected,
										</li>
										<li>
											the Website is free of viruses or
											other harmful components,
										</li>
										<li>
											the results of using the Website
											will meet your requirements.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							<h2>Limitation of Liability</h2>
							<ol type="number-item">
								<li>
									SUPERHUIT and SUPERHUIT’s employees shall
									not be liable for any damages, liability or
									losses arising out of:
									<ol type="lower-roman">
										<li>
											your use of or reliance on the
											Website, in particular information
											made available on the Website by
											third-parties,
										</li>
										<li>
											your inability to access or use the
											Website, or
										</li>
										<li>
											any transaction or relationship
											between you and any third-party,
											even if SUPERHUIT has been advised
											of the possibility of such damages.
										</li>
									</ol>
								</li>
								<li>
									In any event, SUPERHUIT and SUPERHUIT’s
									employees shall not be liable for indirect,
									incidental, special, exemplary, punitive, or
									consequential damages, including lost
									profits, lost data, personal injury, or
									property damage related to, in connection
									with, or otherwise resulting from any access
									or use of the Website, regardless of the
									negligence (either active, affirmative, sole
									or concurrent) of SUPERHUIT, even if
									SUPERHUIT has been advised of the
									possibility of such damages.
								</li>
							</ol>
						</li>
						<li>
							<h2>Data Privacy</h2>
							<ol type="number-item">
								<li>
									As regards the data privacy related to the
									use of the Website, please refer to the Web
									and
									<Link
										to="/en/privacy-policy/"
										data-target
										onMouseEnter={() => hoverOn()}
										onMouseLeave={() => hoverOff()}
									>
										{' '}
										Privacy Policy{' '}
									</Link>
									of SUPERHUIT.
								</li>
							</ol>
						</li>
						<li>
							<h2>Miscellaneous</h2>
							<ol type="number-item">
								<li>
									These Terms of use shall be governed and
									construed in accordance with the laws of
									Switzerland, without regard to its conflict
									of law provisions.
								</li>
								<li>
									Our failure to enforce any right or
									provision of these Terms of use will not be
									considered a waiver of those rights.
								</li>
								<li>
									If any provision of these Terms of use is
									held to be invalid or unenforceable by a
									court, the remaining provisions of these
									Terms of use will remain in effect.
								</li>
								<li>
									These Terms of use constitute the entire
									agreement between us regarding our Website,
									and supersede and replace any prior
									agreements we might have between us
									regarding the Website.
								</li>
								<li>
									We reserve the right, at our sole
									discretion, to modify or replace these Terms
									of use at any time.
								</li>
								<li>
									By continuing to access or use our Website
									after those revisions become effective, you
									agree to be bound by the revised terms. If
									you do not agree to the new terms, please
									stop using the Website.
								</li>
								<li>
									If you have any questions about these Terms
									of use, please contact us at
									<a
										href="mailto:legal@superhuit.ch"
										data-target
										onMouseEnter={() => hoverOn()}
										onMouseLeave={() => hoverOff()}
									>
										{' '}
										legal@superhuit.ch
									</a>
									.
								</li>
							</ol>
						</li>
					</ol>

					<div className="footnote">
						<p>Last updated on June 16, 2021</p>
					</div>
				</section>

				<div className="page__background" data-animation-page></div>
			</div>
		</Layout>
	);
};

export default TermsOfUsePage;
